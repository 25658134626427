exports.onRouteUpdate = ({ location }) => {
  if (window.Beacon("info")) {
    window.Beacon("destroy")
  }

  if (location.pathname === "/privacy/cookie/") {
    document.body.classList.add("cookie-policy")
  } else {
    document.body.classList.remove("cookie-policy")
  }
}

// TODO: Add automated tests for this scroll restoration behaviour
exports.shouldUpdateScroll = ({ routerProps: { location } }) => {
  window.history.scrollRestoration = "manual"

  if (location && location.hash) {
    setTimeout(() => {
      const item = document.getElementById(location.hash.replace("#", ""))

      if (item) {
        item.scrollIntoView()
      }
    }, 0)

    return false
  }

  return true
}

exports.onClientEntry = () => {
  !(function (e, t, n) {
    function a() {
      var e = t.getElementsByTagName("script")[0],
        n = t.createElement("script")
      ;(n.type = "text/javascript"),
        (n.async = !0),
        (n.id = "beacon-script"),
        (n.src = "https://beacon-v2.helpscout.net"),
        e.parentNode.insertBefore(n, e)
    }
    if (
      ((e.Beacon = n =
        function (t, n, a) {
          e.Beacon.readyQueue.push({ method: t, options: n, data: a })
        }),
      (n.readyQueue = []),
      "complete" === t.readyState)
    )
      return a()
    e.attachEvent
      ? e.attachEvent("onload", a)
      : e.addEventListener("load", a, !1)
  })(window, document, window.Beacon || function () {})
}
